import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type ModuleSettingsResponse = {
  success: boolean;
};

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    triggerSync: builder.mutation<ModuleSettingsResponse, void>({
      query: () => ({
        url: '/sync',
        method: 'POST',
      }),
    }),
  }),
});

export const { useTriggerSyncMutation } = settingsApi;
