import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import huJSON from './translations/hu.json';

i18n.use(initReactI18next).init({
  resources: {
    hu: { ...huJSON },
  },
  lng: 'hu',
});

export default i18n;
