import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';
import './lang';
import { Login } from './pages/Login';
import './styles/global.css';

import { routes as mainRoutes } from './globals/routes';
import { MainLayout } from './layouts/Main';
import { NotFound } from './pages/NotFound';
import { store } from './store';

function App() {
  const routes = useRoutes([
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: '/',
      element: <Login />,
    },
    {
      element: (
        <ProtectedRoute>
          <MainLayout />
        </ProtectedRoute>
      ),
      children: mainRoutes.map((route) => ({
        path: route.route,
        element: <route.component />,
      })),
    },
  ]);

  return <Provider store={store}>{routes}</Provider>;
}

export default App;
