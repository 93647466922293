import {
  useGetCategoryMostPopularSettingsQuery,
  useSaveCategoryMostPopularSettingsMutation,
} from '@/api/module';
import { SettingsHeader } from '@/components/SettingsHeader';
import { Form, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import { useRenderFormFields } from '@/hooks/useRenderFormFields';
import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import { FormFieldsConfig } from '@/types/forms';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const CategoryMostPopularModule = () => {
  const { t } = useTranslation();
  const { renderField } = useRenderFormFields();
  const formRef = useRef<HTMLFormElement>(null);

  useSetPageTitle(t('pages.categoryMostPopular.title'));

  const { data } = useGetCategoryMostPopularSettingsQuery();
  const [saveSettings, { isLoading: isSavingLoading }] =
    useSaveCategoryMostPopularSettingsMutation();
  const { toast } = useToast();

  const formItemsClasses = 'grid grid-cols-[0.5fr_1fr] items-center space-y-0';

  const formSchema = z.object({
    status: z.string(),
    name: z.string(),
    showName: z.boolean(),
    targetSelector: z.string(),
    targetMode: z.enum(['before', 'after', 'prepend', 'append']),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      status: 'false',
      name: '',
      showName: false,
      targetSelector: '',
      targetMode: 'before',
    },
  });

  useEffect(() => {
    if (data) {
      form.setValue('status', data.status.toString());
      form.setValue('name', data.name);
      form.setValue('showName', data.showName);
      form.setValue('targetSelector', data.targetSelector);
      form.setValue('targetMode', data.targetMode);
    }
  }, [data]);

  const formFields: FormFieldsConfig[] = [
    {
      key: 'status',
      label: t('global.settings.status.label'),
      type: 'select',
      options: [
        { value: 'true', label: t('global.settings.status.options.active') },
        { value: 'false', label: t('global.settings.status.options.inactive') },
      ],
      placeholder: '',
    },
    {
      key: 'showName',
      label: t('global.settings.showName.label'),
      type: 'switch',
    },
    {
      key: 'name',
      label: t('global.settings.name.label'),
      placeholder: 'Kategória legnépszerűbb termékei',
      type: 'input',
    },
    {
      key: 'targetSelector',
      label: t('global.settings.targetSelector.label'),
      type: 'input',
    },
    {
      key: 'targetMode',
      label: t('global.settings.targetMode.label'),
      type: 'select',
      options: [
        { value: 'before', label: t('global.settings.targetMode.options.before') },
        { value: 'after', label: t('global.settings.targetMode.options.after') },
        { value: 'prepend', label: t('global.settings.targetMode.options.prepend') },
        { value: 'append', label: t('global.settings.targetMode.options.append') },
      ],
      placeholder: '',
    },
  ];

  const submitForm = () => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    const requestBody = { ...values, status: values.status === 'true' };
    const response = await saveSettings(requestBody);

    if (response.data?.success) {
      toast({
        variant: 'success',
        description: t('global.settings.save.success'),
      });
    }
  };

  return (
    <div className='flex flex-col gap-y-4'>
      <SettingsHeader
        title={t('pages.categoryMostPopular.title')}
        isSubmitButtonLoading={isSavingLoading}
        onCancel={() => {}}
        onSave={submitForm}
      />

      <div className='bg-white rounded-lg p-10 border border-gray-200 border-r-[1px]'>
        <Form {...form}>
          <form
            className='flex flex-col gap-8 w-2/3'
            onSubmit={form.handleSubmit(handleSubmit)}
            ref={formRef}
          >
            {formFields.map((fieldConfig) => (
              <FormField
                key={fieldConfig.key}
                name={fieldConfig.key}
                render={({ field }) => (
                  <FormItem className={formItemsClasses}>
                    <FormLabel>{fieldConfig.label}</FormLabel>
                    {renderField(field, fieldConfig)}
                  </FormItem>
                )}
              />
            ))}
          </form>
        </Form>
      </div>
    </div>
  );
};
