import { useLoginUserMutation } from '@/api/auth';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { CHECK_TOKEN_NAME } from '@/globals/config';
import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import { IRootState } from '@/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertCircle, Loader2, Target } from 'lucide-react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

export const Login = () => {
  const { t } = useTranslation();
  useSetPageTitle(t('login.title'));
  const error = useSelector((state: IRootState) => state.global.error);

  const formSchema = z.object({
    email: z
      .string({ message: t('login.form.email.error.empty') })
      .min(1, {
        message: t('login.form.email.error.empty'),
      })
      .email(t('login.form.email.error.invalid')),
    password: z.string({ message: t('login.form.password.error.empty') }).min(1, {
      message: t('login.form.password.error.empty'),
    }),
  });

  const navigate = useNavigate();
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);

  const [loginUser, { isLoading }] = useLoginUserMutation();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const response = await loginUser(values);

    if ('error' in response) {
      setIsInvalidData(true);
      return;
    }

    navigate('/dashboard/suggested-for-you');
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  const [cookies] = useCookies();
  const location = useLocation();
  if (location.pathname === '/' && CHECK_TOKEN_NAME in cookies) {
    return <Navigate to='/dashboard/suggested-for-you' />;
  }

  return (
    <div className='min-h-screen flex items-center justify-center'>
      <div className='w-[400px] flex flex-col gap-2 p-8 bg-white rounded-lg s'>
        <h1 className='scroll-m-20 border-b border-slate-100 pb-2 text-3xl font-semibold tracking-tight first:mt-0 mb-8 text-center flex items-center justify-center'>
          <Target className='text-indigo-500 mr-3' size={35} />
          {t('login.title')}
        </h1>

        {error !== null && (
          <Alert variant='destructive' className='mb-4'>
            <AlertCircle className='h-4 w-4' />
            {/* @ts-expect-error interpolation */}
            <AlertDescription dangerouslySetInnerHTML={{ __html: t(`global.errors.${error}`) }} />
          </Alert>
        )}

        {isInvalidData && (
          <Alert variant='destructive' className='mb-4'>
            <AlertCircle className='h-4 w-4' />
            <AlertDescription>{t('login.form.error.invalid')}</AlertDescription>
          </Alert>
        )}

        <Form {...form}>
          <form className='flex flex-col gap-2' onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('login.form.email.label')}</FormLabel>
                  <FormControl>
                    <Input placeholder='minta@example.com' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('login.form.password.label')}</FormLabel>
                  <FormControl>
                    <Input type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className='mt-4 w-full' type='submit'>
              {isLoading ? (
                <Loader2 className='mr-2 h-4 w-4 animate-spin' />
              ) : (
                t('login.form.submit.button.text')
              )}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};
