import { Loader2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from './ui/button';

type SettingsHeaderProps = {
  title: string;
  isSubmitButtonLoading?: boolean;
  onCancel: () => void;
  onSave: () => void;
};

export const SettingsHeader = ({
  title,
  isSubmitButtonLoading = false,
  onCancel,
  onSave,
}: SettingsHeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex'>
      <h1 className='scroll-m-20 text-3xl font-semibold tracking-tight mb-4'>{title}</h1>
      <div className='ml-auto'>
        <Button variant='outline' onClick={onCancel}>
          {t('global.settings.actions.cancel')}
        </Button>
        <Button variant='default' onClick={onSave} className='ml-4'>
          {isSubmitButtonLoading ? (
            <Loader2 className='mr-2 h-4 w-4 animate-spin' />
          ) : (
            t('global.settings.actions.save')
          )}
        </Button>
      </div>
    </div>
  );
};
