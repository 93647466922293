import { CHECK_TOKEN_NAME } from '@/globals/config';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

// TODO: rename this to WithProtectedRoute
export const ProtectedRoute = ({ children }: { children: React.ReactElement }) => {
  const [cookies] = useCookies();

  if (!cookies || !(CHECK_TOKEN_NAME in cookies)) {
    // user is not authenticated
    return <Navigate to='/' />;
  }
  return children;
};
