import { Sidebar } from '@/components/Sidebar';
import { Toaster } from '@/components/ui/toaster';
import { Outlet } from 'react-router-dom';

export const MainLayout = () => {
  return (
    <div className='grid grid-cols-[0.25fr_1fr] h-screen w-screen'>
      <div className='bg-white border-gray-200 border-r-[1px] p-8'>
        <Sidebar />
      </div>
      <div className='bg-slate-50 p-8 pt-16'>
        <Outlet />
        <Toaster />
      </div>
    </div>
  );
};
