import { NavLink } from 'react-router-dom';

type NavItemProps = {
  to: string;
  children: React.ReactNode;
};

export const NavItem = ({ to, children }: NavItemProps) => {
  const classes =
    'inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-primary underline-offset-4 h-10 px-4 py-2 justify-start hover:no-underline';

  return (
    <NavLink
      to={to}
      className={({ isActive }) => `${classes} ${isActive ? 'bg-secondary' : 'hover:bg-secondary'}`}
    >
      {children}
    </NavLink>
  );
};
