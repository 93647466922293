import { ShieldAlert } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <div className='flex flex-col gap-y-6 items-center'>
        <ShieldAlert size={64} />
        <h1 className='text-4xl font-bold'>{t('global.notFound')}</h1>
      </div>
    </div>
  );
};
