import { useTriggerSyncMutation } from '@/api/settings';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import { useTranslation } from 'react-i18next';

export const Settings = () => {
  const { t } = useTranslation();
  useSetPageTitle(t('pages.settings.title'));

  const [triggerSync] = useTriggerSyncMutation();

  const handleButtonClick = async () => {
    const response = await triggerSync();

    console.log('??', response);

    if (response.data?.success) {
      toast({
        variant: 'success',
        description: t('pages.settings.sync.success'),
      });
    }
  };

  return (
    <div className='flex flex-col gap-y-4'>
      <div className='bg-white rounded-lg p-10 border border-gray-200 border-r-[1px]'>
        <h1 className='text-2xl font-bold mb-12'>{t('pages.settings.title')}</h1>

        <div className='flex items-center'>
          <div className='mr-auto'>
            <b>{t('pages.settings.sync.title')}</b>
            <p>{t('pages.settings.sync.description')}</p>
          </div>
          <Button onClick={handleButtonClick}>{t('pages.settings.sync.button.text')}</Button>
        </div>
      </div>
    </div>
  );
};
