import { useLogoutUserMutation } from '@/api/auth';
import { routes } from '@/globals/routes';
import { Route } from '@/types/routes';
import { LogOut, Target } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from './ui/button';
import { NavItem } from './ui/nav-item';

export const Sidebar = () => {
  const { t } = useTranslation();

  const [logoutUser] = useLogoutUserMutation();

  return (
    <div className='flex flex-col h-full'>
      <div className='mb-12 flex items-center gap-x-4'>
        <Target className='text-indigo-500' size={35} />
        <div className='leading-none'>
          <h1 className='scroll-m-20 text-xl font-semibold tracking-tight '>{t('global.title')}</h1>
        </div>
      </div>
      <div className='flex flex-col gap-2'>
        {routes.map((route: Route) => (
          <NavItem key={route.route} to={route.route}>
            {<route.icon className='mr-2 w-5 h-5' />}
            {route.sidebar.title}
          </NavItem>
        ))}
      </div>
      <div className='mt-auto'>
        <Button className='w-full' onClick={() => logoutUser()}>
          <LogOut className='mr-2' size={18} />
          {t('logout')}
        </Button>
      </div>
    </div>
  );
};
