import { FormControl, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { FormFieldsConfig } from '@/types/forms';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

export const useRenderFormFields = () => {
  const renderField = (
    field: ControllerRenderProps<FieldValues, string>,
    fieldConfig: FormFieldsConfig,
  ) => {
    switch (fieldConfig.type) {
      case 'select':
        return (
          <Select onValueChange={field.onChange} value={field.value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={fieldConfig.placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {fieldConfig.options!.map((option: { value: string; label: string }) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );
      case 'switch':
        return <Switch className='ml-0' checked={field.value} onCheckedChange={field.onChange} />;
      case 'input':
        return (
          <>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage className='text-xs' />
          </>
        );
    }
  };

  return {
    renderField,
  };
};
