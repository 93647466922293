import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type ModuleSettingsResponse = {
  name: string;
  showName: boolean;
  status: boolean;
  targetSelector: string;
  targetMode: 'prepend' | 'append' | 'before' | 'after';
};

export const moduleApi = createApi({
  reducerPath: 'moduleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    // Suggested for you module's API endpoints
    getSuggestedForYouSettings: builder.query<ModuleSettingsResponse, void>({
      query: () => ({
        url: 'modules/suggested_for_you',
        method: 'GET',
      }),
    }),
    saveSuggestedForYouSettings: builder.mutation<
      { success: boolean },
      Partial<ModuleSettingsResponse>
    >({
      query: (body) => ({
        url: 'modules/suggested_for_you',
        method: 'POST',
        body,
      }),
    }),

    // Category most popular module's API endpoints
    getCategoryMostPopularSettings: builder.query<ModuleSettingsResponse, void>({
      query: () => ({
        url: 'modules/category_most_popular',
        method: 'GET',
      }),
    }),
    saveCategoryMostPopularSettings: builder.mutation<
      { success: boolean },
      Partial<ModuleSettingsResponse>
    >({
      query: (body) => ({
        url: 'modules/category_most_popular',
        method: 'POST',
        body,
      }),
    }),

    // Similar products module's API endpoints
    getSimilarProductsSettings: builder.query<ModuleSettingsResponse, void>({
      query: () => ({
        url: 'modules/similar_products',
        method: 'GET',
      }),
    }),
    saveSimilarProductsSettings: builder.mutation<
      { success: boolean },
      Partial<ModuleSettingsResponse>
    >({
      query: (body) => ({
        url: 'modules/similar_products',
        method: 'POST',
        body,
      }),
    }),

    // Collateral products module's API endpoints
    getCollateralProductsSettings: builder.query<ModuleSettingsResponse, void>({
      query: () => ({
        url: 'modules/collateral_products',
        method: 'GET',
      }),
    }),
    saveCollateralProductsSettings: builder.mutation<
      { success: boolean },
      Partial<ModuleSettingsResponse>
    >({
      query: (body) => ({
        url: 'modules/collateral_products',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetSuggestedForYouSettingsQuery,
  useSaveSuggestedForYouSettingsMutation,
  useGetCategoryMostPopularSettingsQuery,
  useSaveCategoryMostPopularSettingsMutation,
  useGetSimilarProductsSettingsQuery,
  useSaveSimilarProductsSettingsMutation,
  useGetCollateralProductsSettingsQuery,
  useSaveCollateralProductsSettingsMutation,
} = moduleApi;
