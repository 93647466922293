import { CategoryMostPopularModule } from '@/pages/category-most-popular';
import { HomeModule } from '@/pages/suggested-for-you';
import { Grid2X2, House, Settings as SettingsIcon, Tag } from 'lucide-react';

import { SimilarProductsModule } from '@/pages/similar-products';

import i18n from '@/lang/index';
import { CollateralProductsModule } from '@/pages/collateral-products';
import { Settings } from '@/pages/settings';
import { Route } from '@/types/routes';

export const routes: Route[] = [
  {
    sidebar: {
      title: i18n.t('pages.suggestedForYou.sidebar.title'),
    },
    icon: House,
    route: 'dashboard/suggested-for-you',
    component: HomeModule,
  },
  {
    sidebar: {
      title: i18n.t('pages.categoryMostPopular.sidebar.title'),
    },
    icon: Grid2X2,
    route: 'dashboard/category-most-popular',
    component: CategoryMostPopularModule,
  },
  {
    sidebar: {
      title: i18n.t('pages.similarProducts.sidebar.title'),
    },
    icon: Tag,
    route: 'dashboard/similar-products',
    component: SimilarProductsModule,
  },
  {
    sidebar: {
      title: i18n.t('pages.collateralProducts.sidebar.title'),
    },
    icon: Tag,
    route: 'dashboard/collateral-products',
    component: CollateralProductsModule,
  },
  {
    sidebar: {
      title: i18n.t('pages.settings.sidebar.title'),
    },
    icon: SettingsIcon,
    route: 'dashboard/settings',
    component: Settings,
  },
];
