import { createSlice } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
  name: 'auth',
  initialState: {
    error: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { actions: globalActions } = globalSlice;
